<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div id="chart">
      <div
        ref="lineChart1"
        :style="{ width: '100%', height: '400px'}"
      ></div>
    </div>
    <br>
    <floorplan
      v-if="userData.image_src !== undefined"
      id="exportMapDiv"
      :img-src="require(`@/assets${userData.image_src}`)"
      :image-width="userData.image_witdh"
      :image-height="userData.image_height"
    >
    </floorplan>
    <br>
    <!-- <div>
      <v-btn
      color="primary"
      dark
      @click="exportMaptoImage"
      style="text-transform: none"
    >
      Export Image
      <v-icon
        dark
        right
      >
        {{ icons.mdiFileExportOutline }}
      </v-icon>
    </v-btn>
    </div> -->
    <br>
    <div
      v-if="!isEmpty(userData.searchType)"
      id="mainTable"
    >
      <v-col>
        <v-simple-table
          dense
          :style="userData.searchType === 1 ? 'width:400px': 'width:420px'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-if="userData.searchType === 1"
                  class="text-center"
                >
                  Time
                </th>
                <th
                  v-if="userData.searchType === 2"
                  class="text-center"
                >
                  Date
                </th>
                <th class="text-center">
                  To North
                </th>
                <th class="text-center">
                  To South
                </th>
                <th class="text-center">
                  To East
                </th>
                <th class="text-center">
                  To West
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td
                  class="text-center"
                  :style="userData.searchType === 2 ? 'width:110px':''"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.northCount }}
                </td>
                <td class="text-right">
                  {{ item.southCount }}
                </td>
                <td class="text-right">
                  {{ item.eastCount }}
                </td>
                <td class="text-right">
                  {{ item.westCount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table1FooterData"
                :key="item.time"
                class="yellow"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.northCount }}
                </td>
                <td class="text-right">
                  {{ item.southCount }}
                </td>
                <td class="text-right">
                  {{ item.eastCount }}
                </td>
                <td class="text-right">
                  {{ item.westCount }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
    </div>
    <br>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import store from '@/store'
import trafficStoreModule from './trafficStoreModule'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import floorplan from '@/views/components/blueprint/FloorPlan.vue'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'

const USER_APP_STORE_MODULE_NAME = 'app-traffic'

const userData = ref([])
const currentDate = new Date()
const echarts = require('echarts')

export default {
  components: {
    floorplan,
    Loading,
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      this.loading = true

      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)

      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      console.log(searchStartDate)
      console.log(searchEndDate)

      store
        .dispatch('app-traffic/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          this.loading = false
        })
        .catch(error => {
          // ログイン画面に遷移する
          console.log(error)
          this.loading = false

          this.$router.push({ name: 'error-login' })
        })
    },

    getClass(time, index) {
      let css = ''

      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          css = ''
        } else {
          css = 'Gray'
        }

        return css
      }

      if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      }

      return css
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData1 = []

      for (let i = 0; i < Object.values(val.seriesData1).length; i += 1) {
        const element = Object.values(val.seriesData1)[i]

        let colorCd
        if (element.name === 'To North') {
          colorCd = ['#1a7fc6']
        } else if (element.name === 'To South') {
          colorCd = ['#c00101']
        } else if (element.name === 'To East') {
          colorCd = ['#92d051']
        } else {
          colorCd = ['#7030a0']
        }

        const newData = {
          name: element.name,
          type: element.name === 'Total' ? 'line' : 'bar',
          label: {
            normal: {
              show: true,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
          color: colorCd,
        }
        newSeriesData1.push(newData)
      }

      const lineChartOption1 = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData1,
        },
        yAxis: {},
        series: newSeriesData1,
      }
      let lineChart1 = echarts.getInstanceByDom(this.$refs.lineChart1)
      if (lineChart1 == null) {
        lineChart1 = echarts.init(this.$refs.lineChart1)
      }

      lineChart1.setOption(lineChartOption1, true)
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, trafficStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const startDate = sessionStorage.getItem('startDate')
    if (isEmpty(startDate)) {
      const startDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      sessionStorage.setItem('startDate', startDateFormatted)
      sessionStorage.setItem('endDate', endDateFormatted)
    }

    return {
      userData,
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      loading: true,
      isEmpty,
    }
  },
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
</style>

<style scoped>
.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}

.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000;
  padding: 0px 1px !important;
  font-size: 12px !important;
  white-space: nowrap;
  width: 56px;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  white-space: nowrap;
  width: 56px;
  padding: 0px 5px !important;
}
</style>
